import { useContext, useState } from "react";
import { Context } from "../../index";
import './NavBar.scss'
import './NavBarMedia.scss'
import { ADMIN_ROUTE, BASKET_ROUTE, LOGIN_ROUTE, ORDER_ROUTE, SHOP_ROUTE, STATISTIC_ROUTE } from "../../utils/consts";
import {observer} from "mobx-react-lite"
import { useLocation, useNavigate } from "react-router-dom";
import ConfirmationalModal from "../modals/ConfirmationalModal/ConfirmationalModal";
import { logout } from "../../http/userApi";

const NavBar = observer(() => {
    const {user, product, notification, loader} = useContext(Context)
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const location = useLocation();
    const navigate = useNavigate()
    const [signOutModal, setSignOutModal] = useState(false)

    const handleLogOut = async () => {
        loader.showLoader(false);
        const data = await logout()
        user.setUser({})
        user.setIsAuth(false)
        user.setRole('')
        notification.showNotification(data.message)
        setTimeout(async () => {
            if (location.pathname == SHOP_ROUTE) {
                const data = await logout()
                user.setUser({})
                user.setIsAuth(false)
                user.setRole('')
                notification.showNotification(data.message)
                loader.hideLoader();
            } else {
                navigate(SHOP_ROUTE);
            }
        }, 500)
    }

    const handleConfirm = () => {
        handleLogOut()
        setSignOutModal(false)
    }

    const handleCancel = () => {
        setSignOutModal(false)
    }

    return (
        <div className="navbar">
            <div className="navbar__logo"
                onClick={() => {
                    if (location.pathname !== SHOP_ROUTE) { // Проверка, если мы не находимся на пути SHOP_ROUTE
                        loader.showLoader(false);
                        setTimeout(() => {
                          product.setSelectedType({});
                          product.setSelectedCategory({});
                          navigate(SHOP_ROUTE); // Переход на SHOP_ROUTE
                        }, 500);
                      }
                }}
            >
                Amore sushi
            </div>

            {user.isAuth ?

                <div className="navbar__menu">
                    <div className="navbar__menu__phones">
                        <a href="tel:+373 60 516 691" className="navbar__tel">+373 60 516 691</a>
                        <a href="tel:+373 62 116 432" className="navbar__tel">+373 62 116 432</a>
                    </div>
                    <div className="navbar__menu__wrapper">
                        <button
                            className="navbar__basket navbar__button"
                            onClick={() => {
                                if (location.pathname !== BASKET_ROUTE) {
                                    loader.showLoader(false);
                                    setTimeout(() => {
                                        navigate(BASKET_ROUTE);
                                    }, 500);
                                }
                            }}
                        >
                            <svg width="29" height="31" viewBox="0 0 29 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.6255 30.251C7.93975 30.251 7.36179 30.0159 6.89163 29.5457C6.42146 29.0756 6.18692 28.4982 6.188 27.8135C6.18908 27.1288 6.42363 26.5509 6.89163 26.0796C7.36179 25.6105 7.93975 25.376 8.6255 25.376C9.31125 25.376 9.88867 25.6105 10.3577 26.0796C10.8268 26.5487 11.0619 27.1267 11.063 27.8135C11.0641 28.5003 10.829 29.0777 10.3577 29.5457C9.8865 30.0137 9.30908 30.2488 8.6255 30.251ZM23.8745 30.251C23.1898 30.251 22.6124 30.0159 22.1423 29.5457C21.6721 29.0756 21.437 28.4982 21.437 27.8135C21.437 27.1288 21.6721 26.5509 22.1423 26.0796C22.6124 25.6105 23.1898 25.376 23.8745 25.376C24.5592 25.376 25.1371 25.6105 25.6084 26.0796C26.0775 26.5498 26.312 27.1277 26.312 27.8135C26.312 28.4982 26.0775 29.0756 25.6084 29.5457C25.1382 30.0159 24.5602 30.251 23.8745 30.251ZM6.30662 4.875L10.6438 14.001H21.4256C21.613 14.001 21.7793 13.9539 21.9245 13.8596C22.0707 13.7654 22.1959 13.6354 22.2999 13.4696L26.5509 5.75087C26.6755 5.52121 26.6857 5.31808 26.5817 5.1415C26.4767 4.96383 26.2995 4.875 26.0504 4.875H6.30662ZM5.512 3.25H26.6695C27.3748 3.25 27.9056 3.53817 28.262 4.1145C28.6195 4.68975 28.6385 5.28287 28.3189 5.89387L23.6746 14.3569C23.4395 14.7523 23.134 15.0627 22.7581 15.288C22.3822 15.5133 21.9689 15.626 21.5182 15.626H9.9125L7.93813 19.2498C7.77129 19.5 7.76587 19.7708 7.92188 20.0623C8.07788 20.3537 8.31242 20.4999 8.6255 20.501H26.312V22.126H8.6255C7.67758 22.126 6.97017 21.7262 6.50325 20.9268C6.03633 20.1273 6.02496 19.3245 6.46912 18.5185L8.91313 14.1635L2.938 1.625H0V0H3.96825L5.512 3.25Z" fill="white"/>
                            </svg>
                        </button>
                        <button
                            className="navbar__signOut navbar__button"
                            onClick={() => setSignOutModal(true)} 
                        >
                            Sign out
                        </button>
                    </div>
                </div>

                :
                
                <div className="navbar__menu__wrapper-logIn">
                    <div className="navbar__menu__phones">
                        <a href="tel:+373 60 516 691" className="navbar__tel">+373 60 516 691</a>
                        <a href="tel:+373 62 116 432" className="navbar__tel">+373 62 116 432</a>
                    </div>
                    <button 
                        className="navbar__auth navbar__button"
                        onClick={() => {
                            if (location.pathname !== LOGIN_ROUTE) {
                                loader.showLoader(false);
                                setTimeout(() => {
                                    navigate(LOGIN_ROUTE);
                                }, 500);
                            }
                        }}
                    >
                        Log in
                    </button>
                </div>
            }

            {user.isAuth 
            ?
            <button
                className="navbar__basket__mobile"
                onClick={() => {
                    if (location.pathname !== BASKET_ROUTE) {
                        loader.showLoader(false);
                        setTimeout(() => {
                            navigate(BASKET_ROUTE);
                        }, 500);
                    }
                }}
            >
                <svg width="29" height="31" viewBox="0 0 29 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.6255 30.251C7.93975 30.251 7.36179 30.0159 6.89163 29.5457C6.42146 29.0756 6.18692 28.4982 6.188 27.8135C6.18908 27.1288 6.42363 26.5509 6.89163 26.0796C7.36179 25.6105 7.93975 25.376 8.6255 25.376C9.31125 25.376 9.88867 25.6105 10.3577 26.0796C10.8268 26.5487 11.0619 27.1267 11.063 27.8135C11.0641 28.5003 10.829 29.0777 10.3577 29.5457C9.8865 30.0137 9.30908 30.2488 8.6255 30.251ZM23.8745 30.251C23.1898 30.251 22.6124 30.0159 22.1423 29.5457C21.6721 29.0756 21.437 28.4982 21.437 27.8135C21.437 27.1288 21.6721 26.5509 22.1423 26.0796C22.6124 25.6105 23.1898 25.376 23.8745 25.376C24.5592 25.376 25.1371 25.6105 25.6084 26.0796C26.0775 26.5498 26.312 27.1277 26.312 27.8135C26.312 28.4982 26.0775 29.0756 25.6084 29.5457C25.1382 30.0159 24.5602 30.251 23.8745 30.251ZM6.30662 4.875L10.6438 14.001H21.4256C21.613 14.001 21.7793 13.9539 21.9245 13.8596C22.0707 13.7654 22.1959 13.6354 22.2999 13.4696L26.5509 5.75087C26.6755 5.52121 26.6857 5.31808 26.5817 5.1415C26.4767 4.96383 26.2995 4.875 26.0504 4.875H6.30662ZM5.512 3.25H26.6695C27.3748 3.25 27.9056 3.53817 28.262 4.1145C28.6195 4.68975 28.6385 5.28287 28.3189 5.89387L23.6746 14.3569C23.4395 14.7523 23.134 15.0627 22.7581 15.288C22.3822 15.5133 21.9689 15.626 21.5182 15.626H9.9125L7.93813 19.2498C7.77129 19.5 7.76587 19.7708 7.92188 20.0623C8.07788 20.3537 8.31242 20.4999 8.6255 20.501H26.312V22.126H8.6255C7.67758 22.126 6.97017 21.7262 6.50325 20.9268C6.03633 20.1273 6.02496 19.3245 6.46912 18.5185L8.91313 14.1635L2.938 1.625H0V0H3.96825L5.512 3.25Z" fill="white"/>
                </svg>
            </button> 

            :

            <button
                className="navbar__auth__mobile"
                onClick={() => {
                    if (location.pathname !== LOGIN_ROUTE) {
                        loader.showLoader(false);
                        setTimeout(() => {
                            navigate(LOGIN_ROUTE);
                        }, 500);
                    }
                }}
            >
                Log in
            </button>
            }

            <div 
                className="navbar__hamburger"
                onClick={() => setIsMenuOpen(true)}
                >
                <div className="navbar__hamburger__line"></div>
                <div className="navbar__hamburger__line"></div>
                <div className="navbar__hamburger__line"></div>
            </div>

            <div className={isMenuOpen ? "navbar__menu__mobile show" : "navbar__menu__mobile"}>
                <div className="navbar__menu__mobile__header">

                    <div 
                        className="navbar__hamburger__close__wrapper"
                        onClick={() => setIsMenuOpen(false)}
                    >
                        <div 
                            className="navbar__hamburger__line-close">

                        </div>
                        <div 
                            className="navbar__hamburger__line-close-second">
                                
                        </div>
                    </div>
                    
                </div>
                <div className="navbar__menu__mobile__wrapper">
                    <div className="navbar__menu__mobile__item">
                        <a href="tel:+373 60 516 691" className="navbar__tel__mobile">+373 60 516 691</a>
                    </div>
                    <div className="navbar__menu__mobile__item">
                        <a href="tel:+373 60 516 691" className="navbar__tel__mobile">+373 60 516 691</a>
                    </div>
                    
                    {user.user.role == 'ADMIN' 
                    &&
                    <div className="navbar__menu__mobile__item" onClick={() => navigate(ADMIN_ROUTE)}>Admin</div>
                    }
                    {user.isAuth 
                    &&
                    <div className="navbar__menu__mobile__item" onClick={() => setSignOutModal(true)}>Sign out</div>
                    }
                </div>
            </div>

            {user.user.role == 'ADMIN' 
            &&
            <div className="navbar__admin">
                <div className="navbar__admin__wrapper">
                    <div className="navbar__admin__item" onClick={() => navigate(ADMIN_ROUTE)}>Admin</div>
                    <div className="navbar__admin__item" onClick={() => navigate(ORDER_ROUTE)}>Comenzile</div>
                    <div className="navbar__admin__item" onClick={() => navigate(STATISTIC_ROUTE)}>Utilizatorii</div>
                </div>
            </div>
            }
            <ConfirmationalModal isVisible={signOutModal} setIsVisible={setSignOutModal} onConfirm={handleConfirm} onCancel={handleCancel} text={'Dorești să ieși din cont?'}/>
        </div>
    );
});


export default NavBar;