import Admin from "./pages/Admin/Admin";
import { ADMIN_ROUTE, BASKET_ROUTE, SHOP_ROUTE, LOGIN_ROUTE, REGISTRATION_ROUTE, PRODUCT_ROUTE, CATEGORY_ROUTE, ORDER_ROUTE, STATISTIC_ROUTE, USER_ROUTE } from "./utils/consts";
import Basket from "./pages/Basket/Basket"
import Shop from "./pages/Shop/Shop"
import Auth from "./pages/Auth/Auth"
import ProductPage from "./pages/ProductPage/ProductPage"
import CategoryPage from "./pages/CategoryPage/CategoryPage";
import Order from "./pages/Order/Order";
import Statistic from "./pages/Statistic/Statistic";
import UserPage from "./pages/UserPage/UserPage";

export const authRoutes = [
    {
        path: ADMIN_ROUTE,
        Component: Admin
    },
    {
        path: ORDER_ROUTE,
        Component: Order
    },
    {
        path: STATISTIC_ROUTE,
        Component: Statistic
    },
    {
        path: USER_ROUTE + '/:id',
        Component: UserPage
    },
    {
        path: BASKET_ROUTE,
        Component: Basket
    }
]

export const publicRoutes = [
    {
        path: SHOP_ROUTE,
        Component: Shop
    },
    {
        path: LOGIN_ROUTE,
        Component: Auth
    },
    {
        path: REGISTRATION_ROUTE,
        Component: Auth
    },
    {
        path: PRODUCT_ROUTE + '/:id',
        Component: ProductPage
    },
    {
        path: CATEGORY_ROUTE + '/:id',
        Component: CategoryPage
    }
]