import './Footer.scss'
import './FooterMedia.scss'
import Insta from './Insta';

const Footer = () => {
    return (
        <div className='footer'>
            <div className="footer__social">
               <div className="footer__social__logo">
                    Amore Sushi
                </div> 
                <a href="https://www.instagram.com/amore_sushi/" target='blank' className="footer__social__inst">
                    <Insta/>
                    <div className="footer__social__inst__link">amore_sushi</div> 
                </a>
            </div>

            <div className="footer__info">
                <div className="footer__info__address">
                    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 8C15 11.976 8 19 8 19C8 19 1 11.976 1 8C1 4.024 4.134 1 8 1C11.866 1 15 4.024 15 8Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M8 11C9.65685 11 11 9.65685 11 8C11 6.34315 9.65685 5 8 5C6.34315 5 5 6.34315 5 8C5 9.65685 6.34315 11 8 11Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>

                    <div className="footer__info__address__text">
                        Florești, str. Vasile Lupu 60
                    </div>

                </div>

                <a href="#" className="footer__info__privacy">Privacy Policy</a>
            </div>

            <div className="footer__contacts">

                <div className="footer__contacts__tel">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20 15.5C18.8 15.5 17.5 15.3 16.4 14.9H16.1C15.8 14.9 15.6 15 15.4 15.2L13.2 17.4C10.4 15.9 8 13.6 6.6 10.8L8.8 8.6C9.1 8.3 9.2 7.9 9 7.6C8.7 6.5 8.5 5.2 8.5 4C8.5 3.5 8 3 7.5 3H4C3.5 3 3 3.5 3 4C3 13.4 10.6 21 20 21C20.5 21 21 20.5 21 20V16.5C21 16 20.5 15.5 20 15.5ZM5 5H6.5C6.6 5.9 6.8 6.8 7 7.6L5.8 8.8C5.4 7.6 5.1 6.3 5 5ZM19 19C17.7 18.9 16.4 18.6 15.2 18.2L16.4 17C17.2 17.2 18.1 17.4 19 17.4V19Z" fill="white" fillOpacity="0.7"/>
                    </svg>

                     <a href="tel:+37362116432" className="footer__contacts__tel__number">+373 62 116 432</a>
                </div>

                <div className="footer__contacts__tel footer__contacts__tel-second">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20 15.5C18.8 15.5 17.5 15.3 16.4 14.9H16.1C15.8 14.9 15.6 15 15.4 15.2L13.2 17.4C10.4 15.9 8 13.6 6.6 10.8L8.8 8.6C9.1 8.3 9.2 7.9 9 7.6C8.7 6.5 8.5 5.2 8.5 4C8.5 3.5 8 3 7.5 3H4C3.5 3 3 3.5 3 4C3 13.4 10.6 21 20 21C20.5 21 21 20.5 21 20V16.5C21 16 20.5 15.5 20 15.5ZM5 5H6.5C6.6 5.9 6.8 6.8 7 7.6L5.8 8.8C5.4 7.6 5.1 6.3 5 5ZM19 19C17.7 18.9 16.4 18.6 15.2 18.2L16.4 17C17.2 17.2 18.1 17.4 19 17.4V19Z" fill="white" fillOpacity="0.7"/>
                    </svg>

                    <a href="tel:+37360516691" className="footer__contacts__tel__number">+373 60 516 691</a>
                </div>
            </div>
            
        </div>
    );
};

export default Footer;