import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { LOGIN_ROUTE, REGISTRATION_ROUTE, SHOP_ROUTE } from "../../utils/consts";
import { login, registration } from "../../http/userApi";
import { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Context } from "../../index";
import { fetchBasket } from "../../http/basketApi"
import './Auth.scss'
import './AuthMedia.scss'



const Auth = observer(() => {
    const {user, notification, loader} = useContext(Context)
    const location = useLocation()
    const navigate = useNavigate()
    const isLogin = location.pathname === LOGIN_ROUTE
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [isChecked, setIsChecked] = useState(false)

    useEffect(() => {
        loader.showLoader(false);
        setTimeout(() => {
            loader.hideLoader();
        }, 500)
    }, []);

    const click = async () => {
        try {
            loader.showLoader(false);
            let data;
            if (isLogin) {
                try {
                    if (!phone) {
                        loader.hideLoader();
                        return notification.showNotification('Întroduceți numărul!')
                    }

                    if (phone.length > 9) {
                        loader.hideLoader();
                        return notification.showNotification('Numărul este prea lung!')
                    }
    
                    if (!password) {
                        loader.hideLoader();
                        return notification.showNotification('Întroduceți parola!')
                    }
    
                    data = await login(+phone, password)
                    notification.showNotification(data.message)

                } catch (e) {
                    loader.hideLoader();
                    return notification.showNotification(e.message)
                }

            } else {
                try {

                    if (!name) {
                        loader.hideLoader();
                        return notification.showNotification('Întroduceți numele!')
                    }

                    if (name.length < 2) {
                        loader.hideLoader();
                        return notification.showNotification('Numele este prea scurt!')
                    }

                    if (name.length > 21) {
                        loader.hideLoader();
                        return notification.showNotification('Numele este prea lung!')
                    }

                    if (!phone) {
                        loader.hideLoader();
                        return notification.showNotification('Întroduceți numărul!')
                    }

                    if (phone.length > 9) {
                        loader.hideLoader();
                        return notification.showNotification('Numărul este prea lung!')
                    }

                    if (!password) {
                        loader.hideLoader();
                        return notification.showNotification('Întroduceți parola!')
                    }

                    if (password.length < 6) {
                        loader.hideLoader();
                        return notification.showNotification('Parola e prea scurtă!')
                    }

                    if (password.length > 129) {
                        loader.hideLoader();
                        return notification.showNotification('Parola e prea lungă!')
                    }

                    if (!isChecked) {
                        loader.hideLoader();
                        return notification.showNotification('Acceptă politica de confidelitate!')
                    }

                    data = await registration(name, +phone, password)
                    notification.showNotification(data.message)
                } catch (e) {
                    loader.hideLoader();
                    return notification.showNotification(e.message)
                }
            }
            if (!data.userData) {
                loader.hideLoader();
                return notification.showNotification(data.message)
            }
            setTimeout(async () => {
                user.setUser(data.userData)
                user.setIsAuth(true)
                user.setRole(data.userData.role)
                const basketData = await fetchBasket(user.user.id)
                user.setBasket(basketData)
                navigate(SHOP_ROUTE)
            }, 500);

        } catch (e) {
            loader.hideLoader();
            return notification.showNotification(e.message)
        }

    }

    return (
        <div className="auth show">
            <div className="auth__content show">
                <h2 className="auth__header">{isLogin ? 'Autorizare' : 'Registrare'}</h2>
                <form 
                    className="auth__form"
                    onSubmit={(e) => e.preventDefault}
                >
                    {!isLogin 
                    &&
                    <input
                        className="auth__form__input name"
                        placeholder="Numele"
                        value={name}
                        onChange={e => setName(e.target.value)}
                    />
                    }
                    <input
                        className="auth__form__input"
                        placeholder="Numărul de telefon"
                        value={phone}
                        onChange={e => setPhone(e.target.value)}
                    />
                    <div className="auth__form__password-wrapper">
                        <input
                            className="auth__form__input"
                            placeholder="Parola"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            type={showPassword ? "text" : "password"}
                        />
                        <button
                            type="button"
                            className="auth__form__toggle-password"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? 
                            <svg className="auth__form__password__hide" width="23" height="13" viewBox="0 0 23 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.9375 10L17.1531 6.1795M11.5 11.6875V7.75M3.0625 10L5.84012 6.1885M1.375 1C5.425 10 17.575 10 21.625 1" stroke="#7B7B7B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                             
                            : 

                            <svg className="auth__form__password__show" width="24" height="14" viewBox="0 0 24 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.75 8.25C5.25 -1.75 18.75 -1.75 23.25 8.25" stroke="#7B7B7B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M12 13.25C11.5075 13.25 11.0199 13.153 10.5649 12.9645C10.11 12.7761 9.69657 12.4999 9.34835 12.1517C9.00013 11.8034 8.72391 11.39 8.53545 10.9351C8.347 10.4801 8.25 9.99246 8.25 9.5C8.25 9.00754 8.347 8.51991 8.53545 8.06494C8.72391 7.60997 9.00013 7.19657 9.34835 6.84835C9.69657 6.50013 10.11 6.22391 10.5649 6.03545C11.0199 5.847 11.5075 5.75 12 5.75C12.9946 5.75 13.9484 6.14509 14.6517 6.84835C15.3549 7.55161 15.75 8.50544 15.75 9.5C15.75 10.4946 15.3549 11.4484 14.6517 12.1517C13.9484 12.8549 12.9946 13.25 12 13.25Z" stroke="#7B7B7B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>

                            }
                        </button>
                    </div>

                    <div className="auth__form__wrapper__policy">
                        {isLogin 
                            ?
                            <a href="#" className="auth__form__forgot">Ai uitat parola?</a>
                            :
                            <div className="auth__form__checbox__wrapper">
                                <input 
                                    type="checkbox" 
                                    className="auth__form__checkbox"
                                    checked={isChecked}
                                    onChange={() => setIsChecked(!isChecked)}
                                />
                                <div className="auth__form__policy">
                                    Accept 
                                    <a href="#" className="auth__form__policy-link">politica de confidelitate</a>
                                </div>
                            </div>
                        }
                    </div>
                    
                    <button 
                        className="auth__btn"
                        onClick={e => {
                            e.preventDefault()
                            click()
                        }}
                    >
                        {isLogin ? 'Log in' : 'Înregistreazăte'}
                    </button>
                    {isLogin ? 
                    <div className="auth__or__reg">
                        <NavLink to={REGISTRATION_ROUTE}>Nu ai cont?</NavLink>
                    </div>
                    :
                    <div className="auth__or__reg">
                        <NavLink to={LOGIN_ROUTE}>Deja ai cont?</NavLink>
                    </div>}
                </form>
            </div>
        </div>
    );
});

export default Auth;